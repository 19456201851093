<template>
  <v-card class="py-5" elevation="0">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >สรุปรายจ่าย พรบ. และประกันภัย ในรอบบิล</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-row justify="space-between">
            <v-col cols="6" md="5" lg="4">
              <v-autocomplete
                v-model="dealers"
                :items="list.dealers"
                :search-input.sync="search_dealer"
                return-object
                item-text="name"
                item-value="_id"
                outlined
                dense
                label="ชื่อผู้จำหน่าย"
                autocomplete="off"
                @change="
                  (val) => {
                    getDealerById(val._id);
                  }
                "
                auto-select-first
                @keydown="handleDealer()"
              >
                <template slot="item" slot-scope="{ item }">
                  {{ item.name }}</template
                >
              </v-autocomplete>
              <v-text-field
                label="เลขผู้เสียภาษี"
                outlined
                dense
                background-color="white"
                v-model="form.taxpayer_number"
              />
              <v-text-field
                label="สาขา"
                outlined
                dense
                background-color="white"
                v-model="form.branch_office_number"
              />
              <v-textarea
                label="ที่อยู่"
                v-model="addres_holder"
                @click="
                  (dialogAddressData = form.location), (dialogAddress = true)
                "
                outlined
                dense
                background-color="white"
                rows="4"
              />
            </v-col>
            <v-col lg="4">
              <DatePicker
                label="วันที่จ่าย"
                :value="form.date_of_issue"
                @onChange="(val) => (form.date_of_issue = val)"
                hideDetails
                outlined
                dense
                background-color="white"
              />
              <v-col>
                <v-text-field
                  class="mb-2"
                  label="เลขที่เอกสาร"
                  hideDetails
                  outlined
                  dense
                  background-color="white"
                  v-model="form.number"
              /></v-col>
              <!-- <v-text-field label="วันที่จ่าย" outlined dense background-color="white" v-model="form.date_of_issue"/> -->
              <!-- <v-text-field label="ผู้ใช้งาน" outlined dense background-color="white" v-model="form.approval_id"/> -->
              <v-col>
                <v-autocomplete
                  v-model="approvals"
                  :items="list.approval"
                  :search-input.sync="form.approval"
                  return-object
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  label="ผู้ใช้งาน"
                  autocomplete="off"
                  @change="
                    (val) => {
                      getApprovalById(val._id);
                    }
                  "
                  auto-select-first
                  hide-details
                  @keydown="handleApproval()"
                >
                  <template slot="item" slot-scope="{ item }">
                    {{ item.name }}</template
                  >
                </v-autocomplete>
              </v-col>
            </v-col>

            <v-col cols="12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="form.product"
                :loading="loading"
                sort-by="col_a"
                :loading-text="$table_loading_text"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
              >
                <template v-slot:[`item._id`]="{ item }">
                  {{
                    form.product
                      .map(function (x) {
                        return x._id;
                      })
                      .indexOf(item._id) +
                    1 +
                    itemsPerPage * (page - 1)
                  }}
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  {{ formatDate(item.date) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn color="grey" icon @click="remove(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template v-slot:footer>
                  <table-pagination
                    :page.sync="page"
                    :itemsPerPage.sync="itemsPerPage"
                    :length="pageCount"
                  />
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="10">
              <v-row justify="space-between">
                <v-col cols="4"></v-col>
                <v-col cols="7" md="6" lg="5" xl="4" class="pr-7 fz-3">
                  <div class="mb-5">
                    <span>รวมจำนวนเงินทั้งสิ้น</span>
                    <span class="float-right">{{
                      parseFloat(net_price).toFixed(2)
                    }}</span>
                  </div>
                  <div class="mb-10">
                    <span class="primary--text">ยอดจริงที่จ่ายบริษัท</span>
                    <span class="float-right" style="width: 100px">
                      <v-text-field
                        v-model.number="form.actual_price"
                        outlined
                        dense
                        background-color="white"
                        hide-details
                      />
                    </span>
                  </div>
                  <div class="mb-10">
                    <span>ค่าคอมที่เราได้</span>
                    <span class="float-right">{{ commission_price }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="this.$route.params.id && form.payment.type != 0">
              <div class="green--text mb-3 fz-3">บันทึกการชำระเงิน</div>
              <v-card elevation="1" class="mb-5">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="green">
                      <tr>
                        <th class="text-center white--text">วันที่ชำระ</th>
                        <th class="text-center white--text">ช่องทาง</th>
                        <th class="text-center white--text">รายละเอียด</th>
                        <th class="text-center white--text">ยอดรับชำระ</th>
                        <th class="text-center white--text">หมายเหตุ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          {{ formatDate(form.payment.date)  }}
                          {{ form.payment.date | moment($formatTime) }}
                        </td>
                        <td class="text-center">
                          {{ form.payment.type == 1 ? 'เงินสด' : '' }}
                          {{ form.payment.type == 2 ? 'โอนเงิน' : '' }}
                          {{ form.payment.type == 3 ? 'บัตรเครดิต' : '' }}
                        </td>
                        <td class="text-center">
                          {{ form.payment.reference }}
                        </td>
                        <td class="text-center">
                          {{ form.payment.amount | numeral('0,0.00') }}
                        </td>
                        <td class="text-center">{{ form.payment.note }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>

            <v-col cols="12" class="d-flex">
              <v-btn class="px-5" color="grey" @click="close()" outlined>
                ปิด
              </v-btn>
              <v-spacer />
              <v-btn
                class="px-10"
                color="primary"
                @click="save()"
                :loading="loading"
                :disabled="loading"
              >
                บันทึกข้อมูล
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import AddressDetail from '@/components/Address/AddressDetail.vue';
import DatePicker from '@/components/DatePicker.vue';

import * as moment from 'moment';
export default {
  components: {
    AddressDetail,
    DatePicker,
  },
  props: ['process_array'],
  data() {
    return {
      loading: true,
      dealers: null,
      approvals: null,
      search_dealer: '',
      isUpdate: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      list: {
        dealers: [],
        approval: [],
      },
      dialogAddress: false,
      dialogAddressData: {
        address: '',
        district: '',
        sub_district: '',
        province: '',
        postal_code: '',
      },
      headers: [
        { text: 'ลำดับ', value: '_id', filterable: false },
        { text: 'วันที่', value: 'date' },
        { text: 'ทะเบียน', value: 'car_plate', filterable: false },
        { text: 'จังหวัด', value: 'car_province', filterable: false },
        { text: 'ชื่อสินค้า', value: 'product_name', filterable: false },
        { text: 'สาขา', value: 'branch_name', filterable: false },
        { text: 'เบี้ยรวม', value: 'price', filterable: false },
        { text: '', value: 'actions', filterable: false },
      ],
      form: {
        branch_id: '',
        approval_id: '',
        approval: '',
        expense_insurance_id: [],
        dealer_id: '',
        dealer_name: '',
        taxpayer_number: '',
        branch_office_number: '',
        location: {
          address: '',
          district: '',
          sub_district: '',
          province: '',
          postal_code: '',
        },
        _id: '',
        date_of_issue: moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss'),
        number: '',
        status: 0,
        product: [],
        approval_id: '',
        net_price: 0,
        actual_price: 0,
        commission_price: 0,
        payment: {
          type: 0,
          reference: '',
          note: '',
          date: moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format('YYYY-MM-DDTHH:mm:ss'),
          amount: 0,
        },
      },

      payList: [
        { id: 1, col_a: 'a', col_b: 'b', col_c: 'c', col_d: 'd' },
        { id: 2, col_a: 'a', col_b: 'b', col_c: 'c', col_d: 'd' },
      ],
    };
  },
  async created() {
    this.form.branch_id = this.$store.state.selected_branch._id;
    this.loading = true;
    this.form.product = this.process_array;
    await this.getDealer();
    await this.getApproval();
    await this.getExpenseNumber();
    this.isUpdate = this.$route.params.id ? true : false;
    if (this.isUpdate) {
      await this.getData();
      await this.getApprovalById(this.$store.state.profile._id);
    } else {
      await this.getApprovalById(this.$store.state.profile._id);
    }
    this.loading = false;
  },
  methods: {
    async save() {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            this.form.net_price = this.net_price;
            this.form.commission_price = this.commission_price;
            this.form.expense_insurance_id = this.process_array.map(
              (x) => x._id
            );
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            console.log(this.form);
            await this.$axios
              .post(`${this.$baseUrl}/expense/create_insurance_expense`, body)
              .then(async (res) => {
                this.$alertSuccess({ title: res.status });
                this.close();
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async close() {
      this.$router.push({ name: 'expenses-insurance' });
    },
    async remove(item) {
      let index = this.form.product.indexOf(item);
      this.form.product.splice(index, 1);
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(
          { expense_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense/get_by_id`, body)
        .then(async (res) => {
          this.form = res.result;
          console.log(this.form);
          if (res.result.location == '') {
            this.form.location = {
              address: '',
              district: '',
              sub_district: '',
              province: '',
              postal_code: '',
            };
          }
          this.getDealerById(this.form.dealer_id);
          if (this.form.status == 1 || this.form.status == 2)
            await this.getApprovalById(this.form.approval_id);
        })
        .catch((err) => {
          console.log('err', err);
        });
      this.loading = false;
    },
    async getExpenseNumber() {
      let filter = {
        branch_id: this.form.branch_id,
        expense_type: 2,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense/check_expense_number`, body)
        .then((res) => {
          this.form.number = res.result;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async getApproval(text = '') {
      let filter = {
        item_per_page: 50,
        page: 1,
        start_with: text,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/users/get_user`, body)
        .then((res) => {
          this.list.approval = res.result.data;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async getDealer(text = '') {
      let filter = {
        item_per_page: 30,
        page: 1,
        start_with: text,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/dealer/get_dealer`, body)
        .then((res) => {
          this.list.dealers = res.result.data;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async getApprovalById(approval_id) {
      this.form.approval_id = approval_id;
      let body = {
        token: this.$jwt.sign({ user_id: approval_id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/users/get_by_id`, body)
        .then(async (res) => {
          let data = res.result;
          if (data) {
            this.approvals = data;

            this.form.approval_id = data._id;
            this.form.approval = data.name;
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    async getDealerById(dealer_id) {
      if (dealer_id) {
        let body = {
          token: this.$jwt.sign({ dealer_id: dealer_id }, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/dealer/get_by_id`, body)
          .then(async (res) => {
            let data = res.result;
            this.dealers = data;

            this.form.dealer_id = dealer_id;
            this.form.dealer_name = data.name;
            this.form.taxpayer_number = data.taxpayer_number;
            this.form.branch_office_number = data.branch_office;
            this.form.location = data.location;
            if (data.location == '') {
              this.form.location = {
                address: '',
                district: '',
                sub_district: '',
                province: '',
                postal_code: '',
              };
            }
            this.search_dealer = data.name;
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    },
    handleDealer() {
      setTimeout(() => {
        let text = this.search_dealer || '';
        if (String(text).length == 0) {
          this.dealers = null;

          this.form.dealer_name = '';
        }
        this.getDealer(text);
      }, 250);
    },
    handleApproval() {
      setTimeout(() => {
        let text = this.form.approval || '';
        if (String(text).length == 0) {
          this.approvals = null;

          this.form.approval_id = '';
          this.form.approval = '';
        }
        this.getApproval(text);
      }, 250);
    },
    onSubmitAddress(val) {
      this.form.location = val;
      this.dialogAddress = false;
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
  },
  computed: {
    addres_holder() {
      return (
        this.form.location.address +
        ' ' +
        this.form.location.sub_district +
        ' ' +
        this.form.location.district +
        ' ' +
        this.form.location.province +
        ' ' +
        this.form.location.postal_code
      );
    },
    net_price() {
      if (!this.loading) {
        let price = 0;
        if (
          !(this.form.product === undefined || this.form.product.length == 0)
        ) {
          this.form.product.forEach((product) => {
            price += parseFloat(product.price || 0);
          });
        }
        return price;
      }
    },
    commission_price() {
      if (!this.loading) {
        let price = 0;
        price =
          Math.ceil(
            (this.net_price - this.form.actual_price + Number.EPSILON) * 100
          ) / 100;
        if (price == 0.01) price = 0;
        return price;
      }
    },
  },
};
</script>
